import { useCallback, useContext, useMemo, useState } from "react"
import Api from "../services/Api"
import { AppContext } from "../contexts/AppContext";
import {ClientListType} from "../definitions/clientListTypes";

/**
 * Fetch client details from the API
 * @param {string} clientId Client UUID
 * @param {object} updater Increase to update data
 * @returns {ClientListType} ClientList object
 */
export default function useFetchClientListItem(clientId: string | undefined, updater?: number) {
  const { currentUser } = useContext(AppContext);
  const [data, setData] = useState<ClientListType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [prevUpdater, setPrevUpdated] = useState(updater);

  /**
   * Fetch data
   */
  const fetchData = useCallback((clientId: any) => {
    // Store data in cache
    setIsLoading(true);
    setData({} as ClientListType);

    Api.getClientListItem(clientId).then((list: any) => {
      if (list) {
        setData(list);
      }
      setIsLoading(false);
    }).catch((e: any) => {
      setError(e);
      setIsLoading(false);
    });
  }, [currentUser]);

  // Fetch data when query has changed
  useMemo(() => {
    if (clientId && (!data || prevUpdater !== updater)) {
      fetchData(clientId);
      setPrevUpdated(updater)
    }
  }, [clientId, fetchData, data, updater]);



  return [data, isLoading, error]
}