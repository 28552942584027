import React, {FC, useContext, useEffect, useMemo, useState} from "react";
import {ListItem, ListWrapper} from "../components/List";
import Header from "../components/Header";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Overlay from "../components/Overlay";
import useGetUserPriveleges from "../hooks/useGetUserPriveleges";
import ErrorMessage from "../components/ErrorMessage";
import {AppContext} from "../contexts/AppContext";
import Api from "../services/Api";
import useFetchMuseumsList from "../hooks/useFetchMuseumsList";
import {type EkulturMuseum, type Museum} from "../definitions/museumTypes";
import useFetchEkulturMuseumsList from "../hooks/useFetchEkulturMuseumsList";
import SearchBar from "../components/SearchBar";


/**
 * List museums
 * @returns {JSX.Element} Component template
 */
const MuseumsList: FC = () => {
    const {t} = useTranslation();
    const {bypassGroup} = useContext(AppContext);
    const navigate = useNavigate();
    const [updateTrigger, setUpdateTrigger] = useState<number>(0);
    const [museumsList, isLoading, error] = useFetchMuseumsList({}, updateTrigger);
    const [ekulturMuseumsList, isLoadingEkulturMuseums, errorEkulturMuseums] = useFetchEkulturMuseumsList({});

    const priveleges = useGetUserPriveleges();
    const [searchTerm, setSearchTerm] = useState("");

    const filteredEkulturMuseumsList = useMemo(()=> ekulturMuseumsList?.filter((museum: EkulturMuseum) => {
        return museum.name.toLowerCase().includes(searchTerm.toLowerCase())
    }), [searchTerm, ekulturMuseumsList])

    const onClickHandler = async (museum: EkulturMuseum)=>{
        if (window.confirm(`${t("museumsList.confirmAdd")}: ${museum.name}?`)) {
            await Api.addMuseum(museum.id, museum.name)
            setUpdateTrigger(prev => prev + 1)
        }
    }

    // Update title
    useEffect(() => {
        document.title = `${t("menu.museumsList")} - Kulturio Remote`;
    }, [t]);



    if (!["superadmin", "superuser"].includes(priveleges?.role) && bypassGroup?.length === 0) {
        if (!priveleges) {
            return null
        }
        return <ErrorMessage title={t("error.missingPriveleges") || "Missing priveleges"}
                             message={t("error.missingPrivelegesBody") || ""}/>;
    }

    if (error || errorEkulturMuseums) {
        return <ErrorMessage title={t("error.fetch") || "Fetch error"} message={t("error.fetchBody") || ""}/>
    }

    return (
        <>
            <Header title={t("menu.museumsList") || ""} showSearchBar={false}/>
            {(isLoading  || isLoadingEkulturMuseums)&& (<Overlay visibility={isLoading} messageType="loading"/>)}

            <ListWrapper title={t("museumsList.listOfAddedMuseums") || ""}>
                {museumsList?.map((museum: Museum, j: number) => {
                    return <ListItem key={`museum${museum.id}`} title={museum.ownerName} showChevron={false}
                                     tags={[t("museumsList.added")]} state={"active"}/>
                })}
            </ListWrapper>

            <ListWrapper title={t("museumsList.listOfEkulturMuseums") || ""}>
                <SearchBar env={"mobile"} searchPhrase={searchTerm} setSearchPhrase={setSearchTerm}></SearchBar>
                {filteredEkulturMuseumsList?.map((museum: EkulturMuseum, j: number) => {
                    const isAdded = museumsList.find((addedMuseum: Museum)=>addedMuseum.id == museum.id);
                    return <ListItem key={`ekultur_museum${museum.id}`} title={museum.name} showChevron={true}
                                     tags={[isAdded ? t("museumsList.added") : t("museumsList.notAdded")]} state={isAdded ? "active" : "inactive"}
                    onClick={()=>onClickHandler(museum)}/>
                })}
            </ListWrapper>
        </>
    );
}

export default MuseumsList;