import { useCallback, useMemo, useState } from "react"
import Api from "../services/Api"
import {type EkulturMuseum, type Museum} from "../definitions/museumTypes";

/**
 * Fetch museums list from the Ekultur Museums API
 * @param {object} query Fetch query options
 * @param {object} options 
 * @returns {object} Ekultur Museum Api object
 */
export default function useFetchEkulturMuseumsList(query: any) {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();



  const museumsSort = (a: EkulturMuseum, b: EkulturMuseum) =>{
    if (a?.name < b?.name) return -1;
    if (a?.name > b?.name) return 1;
    return 0
  }

  /**
   * Fetch data
   */
  const fetchData = useCallback((query: any) => {
    // Store data in cache
    setIsLoading(true);
    setData([]);

    Api.getEkulturMuseumsApiList({ options: query}).then((list: any) => {
      if (list && list.museums) {
        const museums = list.museums
        museums.sort(museumsSort)
        setData(museums);
      }
      setIsLoading(false);
    }).catch((e: any) => {
      setError(e);
      setIsLoading(false);
    });
  }, []);

  // Fetch data when query has changed
  useMemo(() => {
    if (!data) {
      fetchData(query);
    }
  }, [query, fetchData, data]);

  return [data, isLoading, error]
}