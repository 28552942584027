import { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronRight } from "../assets/chevron_right.svg"; 

interface ListWrapperProps {
  title?: string,
  children?: JSX.Element | JSX.Element[]
}

interface ListItemProps {
  title?: string,
  state?: string,
  icon?: string,
  radio?: boolean,
  checked?: boolean,
  tags?: string[],
  children?: JSX.Element | JSX.Element[]
  showChevron?: boolean,
  onClick?: (...args: any) => void | Promise<void>
}

interface ListHeaderProps {
  title?: string
}

const Wrapper = styled.section``;

const ListTitle = styled.h2`
  margin: 0;
  padding: 6px 15px;
  font-size: .8em;
  text-transform: uppercase;
  background-color: #ECEBEF;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListElement = styled.li`
  margin: 0;
  padding: 10px 15px;
  background: #fff;
  border-bottom: 1px solid #eaeaea;
  float: left;
  width: 100%;
  min-height: 55px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  &:active {
    background-color: #fafafa;
  }
`;

const ActiveState = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  float: left;
  margin-right: 15px;
  flex-shrink: 0;
  background-color: #D46F6F;

  &.active {
    background-color: #99B898;
  }
`;

const Radio = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  float: left;
  margin-right: 15px;
  flex-shrink: 0;
  background-color: #D46F6F;
  position: relative;
  cursor: pointer;

  &.checked::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    background-color: #000;
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }

  &.active {
    background-color: #99B898;
  }
`;

const Main = styled.div`
  overflow: hidden;
  max-width: 80%;
`;

const Title = styled.span`
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Tags = styled.ul`
  margin: 2px 0 0;
  padding: 0;
  list-style: none;
`;

const Tag = styled.li`
  background: #ECEBEF;
  padding: 3px 6px;
  border-radius: 5px;
  font-size: 0.8em;
  margin: 2px 5px 0 0;
  display: block;
  float: left;
`;

const Chevron = styled(ChevronRight)`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  flex-shrink: 0;
`;

const Icon = styled.span`
  float: left;
  line-height: 15px;
  margin: 0 18px 0 2px;
  width: 20px;
  display: block;
  font-size: 1.6em;
`;

/**
 * Render the list wrapper component
 * @returns {JSX.Element} Component template
 */
export const ListWrapper: FC<ListWrapperProps> = ({title, children}) => {
  return (
    <Wrapper>
      <ListHeader title={title}/>
      <List>
        {children}
      </List>
    </Wrapper>
  );
}

/**
 * Render the list item component
 * @returns {JSX.Element} Component template
 */
export const ListItem: FC<ListItemProps> = ({title, state, icon, tags, radio, checked, showChevron, children, onClick}) => {

  // Support old Font-Awesome icons
  switch(icon) {
    case "video":
      icon = "videocam";
      break;
    case "play":
      icon = "play_arrow";
      break;
    case "display":
      icon = "tv";
      break;
    case "volume-mute":
      icon = "volume_mute";
      break;
    case "volume-down":
      icon = "volume_down";
      break;
    case "volume-up":
      icon = "volume_up";
      break;
    case "mosaic":
      icon = "apps";
      break;
  }

  return (
    <ListElement onClick={onClick}>
      {radio && (<Radio className={`${state} ${checked && "checked"}`}></Radio>)}
      {(state && !radio) && (<ActiveState className={state}/>)}
      {icon && (<Icon className="material-icons">{icon}</Icon>)}
      {title && (
        <>
        <Main>
          <Title>{title}</Title>
          {tags && (<Tags>{tags?.map((tag: string, i: number) => { return <Tag key={`tag${i}`}>{tag}</Tag> })}</Tags>)}
        </Main>
        {showChevron && (<Chevron/>)}
        </>
      )}
      {children}
    </ListElement>
  );
}

/**
 * Render the list header
 * @returns {JSX.Element} Component template
 */
export const ListHeader: FC<ListHeaderProps> = ({title}) => {
  if (!title) { return null; }

  return (
    <ListTitle>{title}</ListTitle>
  )
}