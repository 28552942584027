import { Route, Routes } from "react-router-dom";
import ClientList from "./views/ClientList";
import NotFoundView from "./views/NotFoundView";
import ConfigEdit from "./views/ConfigEdit";
import VerifyLogin from "./views/VerifyLogin";
import Logout from "./views/Logout";
import ClientDetails from "./views/ClientDetails";
import ConfigList from "./views/ConfigList";
import SingleUseCodeList from "./views/SingleUseCodeList";
import MuseumsList from "./views/MuseumsList";

/**
 * Define all app routes
 * @returns {JSX.Element} Component template
 */
const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ClientList />}/>
        <Route path="/clients/:clientId" element={<ClientDetails />}/>
        <Route path="/config" element={<ConfigList />}/>
        <Route path="/codes" element={<SingleUseCodeList />}/>
        <Route path="/museums" element={<MuseumsList />}/>
        <Route path="/config/new" element={<ConfigEdit />}/>
        <Route path="/config/:configId" element={<ConfigEdit />}/>
        <Route path="/logout" element={<Logout />}/>
        <Route path="/oauth2/callback" element={<VerifyLogin/>}/>
        <Route path="/key/:key" element={<ClientList />}/>
        <Route path="*" element={<NotFoundView />}/>
      </Routes>
    </>
  );
}

export default AppRoutes;