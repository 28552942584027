import { useCallback, useContext, useMemo, useState } from "react"
import Api from "../services/Api"
import { AppContext } from "../contexts/AppContext";

/**
 * Fetch client details from the API
 * @param {string} id Client UUID
 * @param {object} options 
 * @returns {object} Owner object
 */
export default function useFetchClientDetails(id?: string) {
  const { currentUser } = useContext(AppContext);
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  /**
   * Fetch data
   */
  const fetchData = useCallback((id: any) => {
    // Store data in cache
    setIsLoading(true);
    setData({});

    Api.getClientStatus({id: id, user: currentUser?.uniqueId}).then((list: any) => {
      if (list) {
        setData(list);
      }
      setIsLoading(false);
    }).catch((e: any) => {
      setError(e);
      setIsLoading(false);
    });
  }, [currentUser]);

  // Fetch data when query has changed
  useMemo(() => {
    if (id && !data) {
      fetchData(id);
    }
  }, [id, fetchData, data]);


  return [data, isLoading, error]
}