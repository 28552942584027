import SearchBar from "./SearchBar";
import type {Museum} from "../definitions/museumTypes";
import {ListItem, ListWrapper} from "./List";
import React, {useMemo, useState} from "react";
import useFetchMuseumsList from "../hooks/useFetchMuseumsList";
import Overlay from "./Overlay";
import {useTranslation} from "react-i18next";
import styled from "styled-components";


const Container = styled.div`
    display: flex;
    margin-left: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

interface AddMuseumToClientListProps {
    addMuseumToClientListClickHandler: (museum: Museum) => void;
}


const AddMuseumToClientList: React.FC<AddMuseumToClientListProps> = (
    {
     addMuseumToClientListClickHandler
 }) => {

    const {t} = useTranslation();
    const [museumsList, isLoading, error] = useFetchMuseumsList({});
    const [searchTerm, setSearchTerm] = useState("");
    const filteredEkulturMuseumsList = useMemo(()=> museumsList?.filter((museum: Museum) => {
        return museum.ownerName.toLowerCase().includes(searchTerm.toLowerCase())
    }), [searchTerm, museumsList])

    return (
        <Container>
            {isLoading && (
                <Overlay visibility={isLoading} messageType="loading"/>)}
            <ListWrapper title={t("museumsList.listOfAddedMuseums") || ""}>
                <SearchBar env={"mobile"}
                           searchPhrase={searchTerm}
                           setSearchPhrase={setSearchTerm}/>

                {filteredEkulturMuseumsList?.map((museum: Museum, j: number) => {
                    return <ListItem key={`ekultur_museum${museum.id}`}
                                     title={museum.ownerName} showChevron={true}
                                     tags={[`id: ${museum.id.toString()}`]}
                                     onClick={() => addMuseumToClientListClickHandler(museum)}/>
                })}
            </ListWrapper>

        </Container>
    )
}
export default AddMuseumToClientList