import React, { FC, useContext, useEffect, useState } from "react";
import {ListHeader, ListItem, ListWrapper} from "../components/List";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useFetchClientStatusList from "../hooks/useFetchClientStatusList";
import { timeDiff } from "../common/Measure";
import Overlay from "../components/Overlay";
import useGetUserPriveleges from "../hooks/useGetUserPriveleges";
import ErrorMessage from "../components/ErrorMessage";
import { AppContext } from "../contexts/AppContext";
import useFetchSingleUseCodeList from "../hooks/useFetchSingleUseCodeList";
import Api from "../services/Api";
import styled from "styled-components";
import {t} from "i18next";
import {SingleUseCode} from "../definitions/singleUseCodeTypes";

const CODE_MAX_CHARS = 50;
const CODE_MIN_CHARS = 4;

const TextInput = styled.input`
  padding: 8px 20px;  
  border: none;
  border-radius: 6px;
  margin: 15px 15px;
  font-size: .9em;
  background: #F4F4F4  calc(100% - 15px) 50% no-repeat;
  -webkit-user-select: text;

  @media (min-width: 1000px) {
    margin: 5px 15px;
  }
`
const ErrorText = styled.span`
    margin-left: 1rem;
    color: red;
`;

const FormContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 90px;    
    flex-direction: column;
`
interface SingleUseFormProps{
    setStateUpdate: (value: (((prevState: number) => number) | number)) => void
}


const SingleUseCodeForm: FC<SingleUseFormProps> = ({setStateUpdate}) => {
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState("");
    const handleSubmit = async (evt:any) =>{
        const code = evt.target[0].value
        evt.preventDefault()
        if(code.length > CODE_MAX_CHARS || code.length < CODE_MIN_CHARS ){
            setErrorMsg(t("singleUseCode.validation.length") ?? "")
            return
        }
        if(!(/^([a-zA-Z0-9_-]+)$/.test(code))){
            setErrorMsg(t("singleUseCode.validation.allowedCharacter") ?? "")
            return
        }
        let isError = false
         Api.postSingleUseCode(code).then(()=>{
             window.location.reload();
         }).catch(err=>{
             isError = true;
             console.error(err);
             setErrorMsg(t("singleUseCode.validation.duplicate", {code: code ?? ""}) ?? "")

         }).finally(()=>{
             if (!isError) setErrorMsg("")
         })


    }
    return (
      <FormContainer>
            <ListHeader title={t("singleUseCode.createNewCode") || ""}/>
            <form onSubmit={handleSubmit}>
                <TextInput type="text" name="newCode" placeholder={t("singleUseCode.newCode") || ""}/>
                <button type="submit">{t("singleUseCode.submit") || ""}</button>
                <ErrorText>{errorMsg}</ErrorText>
            </form>
      </FormContainer>
  )
}


/**
 * List clients
 * @returns {JSX.Element} Component template
 */
const SingleUseCodeList: FC = () => {
  const { t } = useTranslation();
  const { bypassGroup } = useContext(AppContext);
  const navigate = useNavigate();
  const [testState, setTestState] = useState(0);
  const [singleUseCodeList, isLoading, error] = useFetchSingleUseCodeList({});
  const availableCodes = singleUseCodeList?.filter((elem: {[key: string]: SingleUseCode})=>!elem.usedByClientId)
  const usedCodes = singleUseCodeList?.filter((elem: {[key: string]: SingleUseCode})=>elem.usedByClientId)
  const priveleges = useGetUserPriveleges();


  // Update title
  useEffect(() => {
    document.title = `${t("menu.singleUseCode")} - Kulturio Remote`;
  }, [t]);

  if (!["superadmin", "superuser"].includes(priveleges?.role) && bypassGroup?.length === 0) {
    if (!priveleges) { return null }
    return <ErrorMessage title={t("error.missingPriveleges") || "Missing priveleges"} message={t("error.missingPrivelegesBody") || ""}/>; }

  if (error) { return <ErrorMessage title={t("error.fetch") || "Fetch error"} message={t("error.fetchBody") || ""}/> }

  return (
    <>
      <Header title={t("menu.singleUseCode") || ""} showSearchBar={false}  />
      {isLoading && (<Overlay visibility={isLoading} messageType="loading"/>)}

      <ListWrapper  title={t("singleUseCode.availableCodes") || ""}>
          {availableCodes?.map((code: SingleUseCode, j: number) => {
            return <ListItem key={`code_available${code.code}`} title={code.code} showChevron={false} tags={["unused"]} state={"active"}/>
          })}
        </ListWrapper>
      <ListWrapper  title={t("singleUseCode.usedCodes") || ""}>
        {usedCodes?.map((code: SingleUseCode, j: number) => {
          return <ListItem key={`code_used${code.code}`} title={code.code} showChevron={false} tags={[`Used by: ${code.usedByClientId}`]} state={"inactive"}/>
        })}
      </ListWrapper>
      <SingleUseCodeForm setStateUpdate={setTestState}/>


    </>
  );
}

export default SingleUseCodeList;