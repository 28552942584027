import { useCallback, useMemo, useState } from "react"
import Api from "../services/Api"
import {SingleUseCode} from "../definitions/singleUseCodeTypes";

/**
 * Fetch single use code list list from the API
 * @param {object} query Fetch query options
 * @param {object} options 
 * @returns {object} SingleUseCodes object
 */
export default function useFetchSingleUseCodeList(query: any) {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();



  const codeListSort = (a: SingleUseCode, b: SingleUseCode) =>{
    if (a?.code < b?.code) return -1;
    if (a?.code > b?.code) return 1;
    return 0
  }

  /**
   * Fetch data
   */
  const fetchData = useCallback((query: any) => {
    // Store data in cache
    setIsLoading(true);
    setData([]);

    Api.getSingleUseCodeList({ options: query}).then((list: any) => {
      if (list) {
        list.sort(codeListSort)
        setData(list);
      }
      setIsLoading(false);
    }).catch((e: any) => {
      setError(e);
      setIsLoading(false);
    });
  }, []);

  // Fetch data when query has changed
  useMemo(() => {
    if (!data) {
      fetchData(query);
    }
  }, [query, fetchData, data]);


  return [data, isLoading, error]
}