import { useCallback, useMemo, useState } from "react"
import Api from "../services/Api"
import {type Museum} from "../definitions/museumTypes";

/**
 * Fetch museums list from the API
 * @param {object} query Fetch query options
 * @param {object} updater Increase to update data
 * @returns {Museum} museum object
 */
export default function useFetchMuseumsList(query: any, updater?: number) {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [prevUpdater, setPrevUpdated] = useState(updater);



  const museumsSort = (a: Museum, b: Museum) =>{
    if (a?.ownerName < b?.ownerName) return -1;
    if (a?.ownerName > b?.ownerName) return 1;
    return 0
  }



  /**
   * Fetch data
   */
  const fetchData = useCallback((query: any) => {
    // Store data in cache
    setIsLoading(true);
    setData([]);

    Api.getMuseumsList({ options: query}).then((list: any) => {
      if (list) {
        list.sort(museumsSort)
        setData(list);
      }
      setIsLoading(false);
    }).catch((e: any) => {
      setError(e);
      setIsLoading(false);
    });
  }, []);

  // Fetch data when query has changed
  useMemo(() => {
    if (!data || prevUpdater !== updater) {
      fetchData(query);
      setPrevUpdated(updater)
    }
  }, [query, fetchData, data, updater]);


  return [data, isLoading, error]
}